@media print {
  .noPrint {
    display: none;
  }
  .MuiPaper-root {
    display: none;
  }
  .prottoy {
    page-break-after: always;
  }
}
